import types from "./actions";

const initialState = {
  socket: null,
  chatData: {},
  typing: false,
  typingData: {},
  totalMsgCount: 0,
  totalChatCount: 0,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_SOCKET:
      console.log(`${types.SET_SOCKET} =>`);
      return {
        ...state,
        socket: action.socket,
      };

    case types.CLEAR_SOCKET:
      console.log(`${types.CLEAR_SOCKET} =>`);
      return {
        ...state,
        socket: null,
      };

    case types.SET_RECEIVED_CHAT_DATA:
      console.log(`${types.SET_RECEIVED_CHAT_DATA} ==>`);
      console.log(action.chatData);
      return {
        ...state,
        chatData: action.chatData,
      };

    case types.CLEAR_CHAT_DATA:
      console.log(`${types.CLEAR_CHAT_DATA} => `);
      return {
        ...state,
        chatData: {},
      };

    case types.SET_TYPING:
      console.log(`${types.SET_TYPING} => `);
      return {
        ...state,
        typing: action.typing,
        typingData: action.typingData,
      };
    case types.SET_TOTAL_CHAT_COUNT:
      console.log(`${types.SET_TOTAL_CHAT_COUNT} => `);
      return {
        ...state,
        totalChatCount: action.data,
      };
    case types.CLEAR_DATA:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
