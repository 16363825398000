import io from "socket.io-client";
import _ from "lodash";
import siteConfig from "@iso/config/site.config";

let SOCKET = null;

const actions = {
  INIT_SOCKET: "socket/INIT_SOCKET",
  EMIT_SOCKET: "socket/EMIT_SOCKET",
  SET_SOCKET: "socket/SET_SOCKET",
  CLEAR_SOCKET: "socket/CLEAR_SOCKET",
  SET_RECEIVED_CHAT_DATA: "socket/SET_RECEIVED_CHAT_DATA",
  CLEAR_CHAT_DATA: "socket/CLEAR_CHAT_DATA",
  SET_TYPING: "socket/SET_TYPING",
  SET_TOTAL_CHAT_COUNT: "socket/SET_TOTAL_CHAT_COUNT",
  CLEAR_DATA: "socket/CLEAR_DATA",

  setSocket: (socket) => (dispatch) =>
    dispatch({
      type: actions.SET_SOCKET,
      socket: socket,
    }),

  onDisConnect: (bool) => (dispatch, getState) => {
    if (bool) {
      dispatch({
        type: actions.CLEAR_SOCKET,
        socket: null,
      });
    }
  },

  onReceive: (chatData) => (dispatch, getState) => {
    if (
      _.isObject(chatData) &&
      _.isObject(chatData.data) &&
      !_.isEmpty(chatData.data)
    ) {
      const cdata = chatData.data;
      cdata.move = "chat";
      console.log(cdata);
      dispatch({
        type: actions.SET_RECEIVED_CHAT_DATA,
        chatData: cdata,
      });
    }
  },

  initialize:
    (callback = () => {}) =>
    (dispatch, getState) => {
      const { socket } = getState().socket;

      if (socket === null || _.isUndefined(socket.emit)) {
        SOCKET = io(siteConfig.socketUrl, {
          autoConnect: true,
          jsonp: false,
          reconnection: true,
          reconnectionAttempts: 1,
          transports: ["websocket"],
        });

        SOCKET.on("connect_error", (error) => {
          console.log("💥️ ~ SOCKET connect_error ->", error);
          if (error?.type == "TransportError") {
            callback(error);
          }
        });

        SOCKET.on("connect", () => {
          console.log("💥️ ~ SOCKET ~ connected ->");
          dispatch(actions.setSocket(SOCKET));
          dispatch(
            actions.userConnect(
              {
                user_id: "bf4dafe7-5c5f-4951-92bd-23c4929f643d",
                admin_chat: true,
              },
              (response) => {
                console.log("💥️ ~ SOCKET user_connection ->", response);
                callback(response);
              }
            )
          );
        });

        SOCKET.on("receiveMessage", (data) => {
          console.log("💥️ ~ SOCKET receiveMessage ->", data);
          dispatch(actions.onReceive(data));
        });

        SOCKET.on("receiveTyping", (data) => {
          console.log("💥️ ~ SOCKET receiveTyping ->", data);
          console.log(data);
          dispatch(actions.onReceiveTyping(data, "start"));
        });

        SOCKET.on("stopTyping", (data) => {
          console.log("💥️ ~ SOCKET stopTyping ->", data);
          dispatch(actions.onReceiveTyping(data, "stop"));
        });

        SOCKET.on("disconnect", () => {
          console.log("SOCKET disconnect =>");
          // dispatch(actions.onDisConnect(true));
        });
      } else {
        console.log("Socket Already connected ==>");
      }
    },

  emit:
    (event, data, callback = () => {}) =>
    (dispatch, getState) => {
      const { socket } = getState().socket;
      if (socket !== null) {
        socket.emit(event, data, (callBackData) => {
          if (event === "user_connection" && callBackData) {
            dispatch(actions.setTotalChatCount(callBackData?.counts));
          }
          if (callback) {
            callback(callBackData);
          }
        });
      }
    },

  onReceiveTyping: (data, type) => (dispatch, getState) => {
    dispatch({
      type: actions.SET_TYPING,
      typing: type === "start",
      typingData: _.isObject(data) && _.isObject(data.data) ? data.data : {},
    });
  },

  userConnect:
    (cData, callback = () => {}) =>
    (dispatch, getState) => {
      const { socket } = getState().socket;
      if (socket !== null) {
        const data = {
          user_id: "bf4dafe7-5c5f-4951-92bd-23c4929f643d",
          page: cData?.page ? cData?.page : 1,
          type: cData?.type ? cData?.type : "s",
          admin_chat: true,
          initial: false,
        };
        console.log("🚀 ~ SOCKET data:", data);
        dispatch(actions.emit("user_connection", data, callback));
      }
    },
  disconnect: () => (dispatch, getState) => {
    const { socket } = getState().socket;
    if (socket !== null) {
      socket.disconnect();
      dispatch(actions.setSocket(null));
    }
  },

  clearChatData: () => (dispatch, getState) => {
    dispatch({
      type: actions.CLEAR_CHAT_DATA,
    });
  },

  setTotalChatCount: (data) => (dispatch) => {
    dispatch({
      type: actions.SET_TOTAL_CHAT_COUNT,
      data,
    });
  },
  clearData: () => (dispatch, getState) => {
    dispatch({
      type: actions.CLEAR_DATA,
    });
  },
};

export default actions;
