import { combineReducers } from "redux";
import App from "@iso/redux/app/reducer";
import Auth from "@iso/redux/auth/reducer";
import ThemeSwitcher from "@iso/redux/themeSwitcher/reducer";
import Notification from "@iso/redux/notification/reducer";
import LanguageSwitcher from "@iso/redux/languageSwitcher/reducer";
import socket from "@iso/redux/socket/reducer";

export default combineReducers({
  Auth,
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  Notification,
  socket,
});
